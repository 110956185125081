@layer modules, ui, base;
@layer ui {
  .sk-CreateOptionItem_main__QR0iA {
  display: -webkit-box;
  z-index: 0;
  align-items: center;
  padding: var(--sk-space-12) var(--sk-space-16);
  overflow: hidden;
  border: none;
  background-color: transparent;
  color: var(--sk-color-black);
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.sk-CreateOptionItem_main__QR0iA:hover {
  background-color: var(--sk-color-dark-10);
}

.sk-CreateOptionItem_main__QR0iA:focus-visible {
  z-index: 1;
  outline: 3px solid var(--sk-color-purple-400);
}

}
@layer ui {
  .sk-Divider_main__KCaPd {
  --Divider-size: calc(var(--sk-size-2) / 2);

  display: inline-flex;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}

.sk-Divider_main--horizontal__3iUOd {
  flex: 0 1 auto;
  justify-content: center;
  width: 100%;
  height: var(--Divider-size);
}

.sk-Divider_main--vertical__F_EbS {
  flex: 0 0 var(--Divider-size);
  align-self: stretch;
  justify-content: center;
  width: var(--Divider-size);
  min-height: 100%;
}

.sk-Divider_main--light__2ofRn {
  background-color: var(--sk-color-dark-20);
}

.sk-Divider_main--dark__DBhlj {
  background-color: var(--sk-color-light-30);
}

}
@layer ui {
  .sk-OptionItem_main__PKJv5 {
  display: flex;
  position: relative;
  z-index: 0;
  align-items: center;
  padding: var(--sk-space-12) var(--sk-space-16);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  cursor: pointer;
  gap: var(--sk-space-4);
}

.sk-OptionItem_main--with-checkbox__wz8E3 {
  display: block;
  padding: 0;
}

.sk-OptionItem_main__PKJv5:hover {
  background-color: var(--sk-color-dark-10);
}

.sk-OptionItem_main__PKJv5:focus-visible {
  z-index: 1;
  outline: 3px solid var(--sk-color-purple-400);
}

.sk-OptionItem_label__AUbyF {
  display: -webkit-box;
  overflow: hidden;
  color: var(--sk-color-black);
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.sk-OptionItem_label--highlighted__1iaUZ {
  font-weight: var(--sk-typography-body-2-bold-font-weight);
}

.sk-OptionItem_main--selected__HcIDa .sk-OptionItem_label__AUbyF {
  font-family: var(--sk-typography-body-2-semibold-font-family);
  font-size: var(--sk-typography-body-2-semibold-font-size);
  font-weight: var(--sk-typography-body-2-semibold-font-weight);
  line-height: var(--sk-typography-body-2-semibold-line-height);
}

.sk-OptionItem_secondary-label__TdzFd {
  overflow: hidden;
  color: var(--sk-color-grey-600);
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sk-OptionItem_icon__4t5fx {
  flex-shrink: 0;
  margin-left: auto;
}

.sk-OptionItem_imageContainer__Nd5Gl {
  display: flex;
  width: var(--sk-space-20);
  height: var(--sk-space-20);
  margin-right: var(--sk-space-4);
}

.sk-OptionItem_imageContainer__Nd5Gl > * {
  border-radius: var(--sk-radius-4);
}

}
@layer ui {
  .sk-Dropdown_main__fxl1w {
  position: absolute;
  z-index: 1;
  box-sizing: border-box;
  width: 100%;
  margin-top: 8px;
  border-radius: var(--sk-form-dropdown-menu-radius);
  box-shadow: var(--sk-form-dropdown-menu-shadow);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.sk-Dropdown_main__option__Y81Aq,
.sk-Dropdown_main__create-option__TUWXS {
  border-radius: var(--sk-form-dropdown-menu-item-radius);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.sk-Dropdown_main__options-list-wrapper__rnVAh {
  padding: var(--sk-space-4);
}

.sk-Dropdown_main__empty__3qFlY {
  padding: var(--sk-space-24) var(--sk-space-12);
  color: var(--sk-color-grey-700);
  font-family: var(--sk-typography-caption-1-font-family);
  font-size: var(--sk-typography-caption-1-font-size);
  font-weight: var(--sk-typography-caption-1-font-weight);
  line-height: var(--sk-typography-caption-1-line-height);
  text-align: center;
}

.sk-Dropdown_main__options-list__EX1L0 {
  max-height: var(--sk-size-320);
  margin: 0;
  padding: 3px;
  overflow-y: auto;
  list-style: none;
  scrollbar-width: thin;
  scrollbar-color: var(--sk-color-grey-100) var(--sk-color-white);
}

.sk-Dropdown_main__option-section__HOObB {
  display: flex;
  flex-direction: column;
}

.sk-Dropdown_main__option-section-label__meXqj {
  display: -webkit-box;
  margin: var(--sk-space-12) var(--sk-space-8);
  overflow: hidden;
  color: var(--sk-color-grey-700);
  font-family: var(--sk-typography-caption-1-font-family);
  font-size: var(--sk-typography-caption-1-font-size);
  font-weight: var(--sk-typography-caption-1-font-weight);
  line-height: var(--sk-typography-caption-1-line-height);
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.sk-Dropdown_main__create-option__TUWXS {
  width: 100%;
}

.sk-Dropdown_main__divider__s7cM7 {
  display: flex;
  width: auto;
  margin: var(--sk-space-12);
}

.sk-Dropdown_main--light__qU_zB {
  background-color: var(--sk-form-dropdown-menu-color-background-light);
  color: var(--sk-form-dropdown-menu-color-light);
}

.sk-Dropdown_main--light__qU_zB .sk-Dropdown_main__option__Y81Aq {
  color: var(--sk-form-dropdown-menu-item-color-default-light);
}

.sk-Dropdown_main--light__qU_zB .sk-Dropdown_main__option__Y81Aq:hover {
  background-color: var(--sk-form-dropdown-menu-item-color-background-hover-light);
  color: var(--sk-form-dropdown-menu-item-color-hover-light);
}

.sk-Dropdown_main--light__qU_zB .sk-Dropdown_main__option__Y81Aq:focus-visible {
  background-color: var(--sk-form-dropdown-menu-item-color-background-focus-light);
  color: var(--sk-form-dropdown-menu-item-color-focus-light);
}

.sk-Dropdown_main--dark__Wllk8 {
  background-color: var(--sk-form-dropdown-menu-color-background-dark);
  color: var(--sk-form-dropdown-menu-color-dark);
}

.sk-Dropdown_main--dark__Wllk8 .sk-Dropdown_main__option__Y81Aq {
  color: var(--sk-form-dropdown-menu-item-color-default-dark);
}

.sk-Dropdown_main--dark__Wllk8 .sk-Dropdown_main__option__Y81Aq:hover {
  background-color: var(--sk-form-dropdown-menu-item-color-background-hover-dark);
  color: var(--sk-form-dropdown-menu-item-color-hover-dark);
}

.sk-Dropdown_main--dark__Wllk8 .sk-Dropdown_main__option__Y81Aq:focus-visible {
  background-color: var(--sk-form-dropdown-menu-item-color-background-focus-dark);
  color: var(--sk-form-dropdown-menu-item-color-focus-dark);
}

}
@layer ui {
  .sk-HiddenInput_main__3BVC1 {
  display: none;
  pointer-events: none;
}

}
@layer ui {
  .sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_trigger__tmRSC {
  cursor: not-allowed;
}

.sk-SearchableSelect_dropdownContainer__D52vX {
  position: relative;
}

.sk-SearchableSelect_input__QRU9_ {
  width: 100%;
  border: none;
  background-color: transparent;
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-overflow: ellipsis;
}

.sk-SearchableSelect_input__QRU9_:-webkit-autofill {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

.sk-SearchableSelect_input__QRU9_:-webkit-autofill,
.sk-SearchableSelect_input__QRU9_:-webkit-autofill:hover,
.sk-SearchableSelect_input__QRU9_:-webkit-autofill:focus,
.sk-SearchableSelect_input__QRU9_:-webkit-autofill:active,
.sk-SearchableSelect_input__QRU9_:autofill {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

.sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_input__QRU9_ {
  cursor: not-allowed;
}

.sk-SearchableSelect_input__QRU9_:focus {
  outline: none;
}

.sk-SearchableSelect_input__QRU9_::-moz-placeholder {
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
}

.sk-SearchableSelect_input__QRU9_::placeholder {
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
}

.sk-SearchableSelect_trigger__tmRSC {
  display: flex;
  position: relative;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  height: var(--sk-size-48);
  padding: var(--sk-space-16);
  border-width: var(--sk-form-select-field-size-border-width);
  border-style: solid;
  border-radius: var(--sk-form-searchable-select-field-radius);
  outline: unset;
  cursor: text;
  place-items: center;
  gap: var(--sk-space-8);
}

.sk-SearchableSelect_trigger__tmRSC .Placeholder {
  flex-grow: 1;
  padding-right: var(--sk-space-4);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.sk-SearchableSelect_trigger__tmRSC .SelectedOptionLabel,
.sk-SearchableSelect_trigger__tmRSC .Placeholder {
  overflow: hidden;
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sk-SearchableSelect_rightIcon__PleOP {
  flex-grow: 0;
  flex-shrink: 0;
}

.sk-SearchableSelect_icon__tmdvd {
  flex-shrink: 0;
}

.sk-SearchableSelect_optionImageContainer__nZLOG {
  width: var(--sk-size-20);
  height: var(--sk-size-20);
}

.sk-SearchableSelect_optionImageContainer__nZLOG > * {
  border-radius: var(--sk-radius-4);
}

/* ON LIGHT BACKGROUND */

.sk-SearchableSelect_main--light__zgITV,
.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_input__QRU9_ {
  color: var(--sk-form-searchable-select-color-light);
}

.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_subLabel__xvHVh {
  color: var(--sk-form-searchable-select-secondary-label-color-light);
}

.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_message__N073R {
  color: var(--sk-form-searchable-select-message-color-default-light);
}

.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_message--error__movCz {
  color: var(--sk-form-searchable-select-message-color-error-light);
}

.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_trigger__tmRSC {
  border-color: var(--sk-form-searchable-select-field-color-border-default-light);
  background-color: var(--sk-form-searchable-select-field-color-background-default-light);
  color: var(--sk-form-searchable-select-field-color-default-light);
}

.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_input__QRU9_::-moz-placeholder {
  color: var(--sk-form-searchable-select-field-placeholder-color-light);
}

.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_input__QRU9_::placeholder {
  color: var(--sk-form-searchable-select-field-placeholder-color-light);
}

.sk-SearchableSelect_main--light__zgITV.sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_trigger__tmRSC {
  border-color: var(--sk-form-searchable-select-field-color-border-read-only-light);
  background-color: var(--sk-form-searchable-select-field-color-background-read-only-light);
  color: var(--sk-form-searchable-select-field-color-read-only-light);
}

.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_icon__tmdvd,
.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_rightIcon__PleOP {
  color: var(--sk-form-searchable-select-field-expand-icon-color-default-light);
}

.sk-SearchableSelect_main--light__zgITV.sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_icon__tmdvd,
.sk-SearchableSelect_main--light__zgITV.sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_rightIcon__PleOP {
  color: var(--sk-form-searchable-select-field-expand-icon-color-read-only-light);
}

.sk-SearchableSelect_main--light__zgITV.sk-SearchableSelect_main--error__8iME5 .sk-SearchableSelect_trigger__tmRSC {
  border-color: var(--sk-form-searchable-select-field-color-border-error-default-light);
}

.sk-SearchableSelect_main--light__zgITV.sk-SearchableSelect_main--error__8iME5 .sk-SearchableSelect_trigger__tmRSC:has(.sk-SearchableSelect_input__QRU9_:focus-visible) {
  border-color: var(--sk-form-searchable-select-field-color-border-error-focus-light);
}

.sk-SearchableSelect_main--light__zgITV.sk-SearchableSelect_main--error__8iME5 .sk-SearchableSelect_trigger__tmRSC:hover:not(:focus-visible) {
  border-color: var(--sk-form-searchable-select-field-color-border-error-hover-light);
}

.sk-SearchableSelect_main--light__zgITV.sk-SearchableSelect_main--error__8iME5 .sk-SearchableSelect_trigger__tmRSC:active {
  border-color: var(--sk-form-searchable-select-field-color-border-error-active-light);
}

.sk-SearchableSelect_main--light__zgITV.sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_trigger__tmRSC:has(.sk-SearchableSelect_input__QRU9_:focus-visible) {
  border-color: var(--sk-form-searchable-select-field-color-border-read-only-focus-light);
  background-color: var(--sk-form-searchable-select-field-color-background-read-only-focus-light);
}

.sk-SearchableSelect_main--light__zgITV:not(.sk-SearchableSelect_main--read-only__WXjhR, .sk-SearchableSelect_main--error__8iME5) .sk-SearchableSelect_trigger__tmRSC:hover {
  border-color: var(--sk-form-searchable-select-field-color-border-hover-light);
  background-color: var(--sk-form-searchable-select-field-color-background-hover-light);
}

.sk-SearchableSelect_main--light__zgITV:not(.sk-SearchableSelect_main--read-only__WXjhR, .sk-SearchableSelect_main--error__8iME5) .sk-SearchableSelect_trigger__tmRSC:active {
  border-color: var(--sk-form-searchable-select-field-color-border-active-light);
  background-color: var(--sk-form-searchable-select-field-color-background-active-light);
}

.sk-SearchableSelect_main--light__zgITV:not(.sk-SearchableSelect_main--read-only__WXjhR, .sk-SearchableSelect_main--error__8iME5) .sk-SearchableSelect_trigger__tmRSC:has(.sk-SearchableSelect_input__QRU9_:focus-visible) {
  border-color: var(--sk-form-searchable-select-field-color-border-focus-light);
  background-color: var(--sk-form-searchable-select-field-color-background-focus-light);
}

.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_clearIcon__be5gE {
  color: var(--sk-form-searchable-select-field-clear-icon-color-light);
}

/* ON DARK BACKGROUND */

.sk-SearchableSelect_main--dark__Un2LI,
.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_input__QRU9_ {
  color: var(--sk-form-searchable-select-color-dark);
}

.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_subLabel__xvHVh {
  color: var(--sk-form-searchable-select-secondary-label-color-dark);
}

.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_message__N073R {
  color: var(--sk-form-searchable-select-message-color-default-dark);
}

.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_message--error__movCz {
  color: var(--sk-form-searchable-select-message-color-error-dark);
}

.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_trigger__tmRSC {
  border-color: var(--sk-form-searchable-select-field-color-border-default-dark);
  background-color: var(--sk-form-searchable-select-field-color-background-default-dark);
  color: var(--sk-form-searchable-select-field-color-default-dark);
}

.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_input__QRU9_::-moz-placeholder {
  color: var(--sk-form-searchable-select-field-placeholder-color-dark);
}

.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_input__QRU9_::placeholder {
  color: var(--sk-form-searchable-select-field-placeholder-color-dark);
}

.sk-SearchableSelect_main--dark__Un2LI.sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_trigger__tmRSC {
  border-color: var(--sk-form-searchable-select-field-color-border-read-only-dark);
  background-color: var(--sk-form-searchable-select-field-color-background-read-only-dark);
  color: var(--sk-form-searchable-select-field-color-read-only-dark);
}

.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_icon__tmdvd,
.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_rightIcon__PleOP {
  color: var(--sk-form-searchable-select-field-expand-icon-color-default-dark);
}

.sk-SearchableSelect_main--dark__Un2LI.sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_icon__tmdvd,
.sk-SearchableSelect_main--dark__Un2LI.sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_rightIcon__PleOP {
  color: var(--sk-form-searchable-select-field-expand-icon-color-read-only-dark);
}

.sk-SearchableSelect_main--dark__Un2LI.sk-SearchableSelect_main--error__8iME5 .sk-SearchableSelect_trigger__tmRSC {
  border-color: var(--sk-form-searchable-select-field-color-border-error-default-dark);
}

.sk-SearchableSelect_main--dark__Un2LI.sk-SearchableSelect_main--error__8iME5 .sk-SearchableSelect_trigger__tmRSC:has(.sk-SearchableSelect_input__QRU9_:focus-visible) {
  border-color: var(--sk-form-searchable-select-field-color-border-error-focus-dark);
}

.sk-SearchableSelect_main--dark__Un2LI.sk-SearchableSelect_main--error__8iME5 .sk-SearchableSelect_trigger__tmRSC:hover:not(:focus-visible) {
  border-color: var(--sk-form-searchable-select-field-color-border-error-hover-dark);
}

.sk-SearchableSelect_main--dark__Un2LI.sk-SearchableSelect_main--error__8iME5 .sk-SearchableSelect_trigger__tmRSC:active {
  border-color: var(--sk-form-searchable-select-field-color-border-error-active-dark);
}

.sk-SearchableSelect_main--dark__Un2LI.sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_trigger__tmRSC:has(.sk-SearchableSelect_input__QRU9_:focus-visible) {
  border-color: var(--sk-form-searchable-select-field-color-border-read-only-focus-dark);
  background-color: var(--sk-form-searchable-select-field-color-background-read-only-focus-dark);
}

.sk-SearchableSelect_main--dark__Un2LI:not(.sk-SearchableSelect_main--read-only__WXjhR, .sk-SearchableSelect_main--error__8iME5) .sk-SearchableSelect_trigger__tmRSC:hover {
  border-color: var(--sk-form-searchable-select-field-color-border-hover-dark);
  background-color: var(--sk-form-searchable-select-field-color-background-hover-dark);
}

.sk-SearchableSelect_main--dark__Un2LI:not(.sk-SearchableSelect_main--read-only__WXjhR, .sk-SearchableSelect_main--error__8iME5) .sk-SearchableSelect_trigger__tmRSC:active {
  border-color: var(--sk-form-searchable-select-field-color-border-active-dark);
  background-color: var(--sk-form-searchable-select-field-color-background-active-dark);
}

.sk-SearchableSelect_main--dark__Un2LI:not(.sk-SearchableSelect_main--read-only__WXjhR, .sk-SearchableSelect_main--error__8iME5) .sk-SearchableSelect_trigger__tmRSC:has(.sk-SearchableSelect_input__QRU9_:focus-visible) {
  border-color: var(--sk-form-searchable-select-field-color-border-focus-dark);
  background-color: var(--sk-form-searchable-select-field-color-background-focus-dark);
}

.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_clearIcon__be5gE {
  color: var(--sk-form-searchable-select-field-clear-icon-color-dark);
}

}
